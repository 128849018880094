import Vue from 'vue'
import VueRouter from 'vue-router'
import userSvc from "./services/accounting/user";
import chantsRoutes from "./views/in/chants/routes";
import adminRoutes from "./views/in/admin/routes";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: "/in/home"
    },
    {
        path: '/in',
        component: () => import('./views/in'),
        children: [
            {
                path: 'home',
                name: 'Home',
                component: () => import('./views/in/home')
            },
            ...chantsRoutes,
            ...adminRoutes,
            {
                path: "whatsapp",
                name: "WhatsApp",
                component: () => import('./views/in/whatsapp')
            },
            {
                path: "user-chart/:chart",
                name: "UserChart",
                component: () => import('./views/in/user-chart')
            }
        ]
    },
    {
        path: '/out',
        component: () => import('./views/out'),
        children: [
            {
                path: 'login',
                name: 'Login',
                component: () => import('./views/out/login')
            },
            {
                name: 'Recover',
                path: 'recover',
                component: () => import('./views/out/recover/start')
            },
            {
                path: 'act/:token',
                component: () => import('./views/out/activation')
            },
            {
                path: 'rst/:token',
                component: () => import('./views/out/recover/resolve')
            },
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {

    return new Promise(resolve => {

        // define if we're requesting a private area link
        let private_area = to.fullPath.indexOf("/out") === -1;
        let admin_area = to.fullPath.indexOf("/admin") > -1;

        userSvc.isLoggedIn().then(logged => {

            // check if user is logged
            if (logged) {

                // ensure user data are loaded
                userSvc.loadInfo().then((user) => {
                    if (private_area) {
                        if (admin_area) {
                            if (user.is_admin) {
                                next();
                            } else {
                                next({name: "Home"});
                            }
                        } else {
                            next();
                        }

                    } else {
                        // avoid public link when logged
                        next({name: "Home"});
                    }
                });

            } else {
                if (private_area) {
                    next({name: "Login"});
                } else {
                    next();
                }
            }
            resolve();
        });
    });
});

export default router