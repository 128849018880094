export default [
    {
        name: "ChantsCollection",
        path: "chants/:collection",
        component: () => import("./listing")
    },
    {
        name: "ChantsDetails",
        path: "chants/:collection/:chant",
        component: () => import("./details")
    }
]
