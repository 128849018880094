export default [
    {
        name: "Admin",
        path: "admin",
        component: () => import("./index")
    },
    {
        path: "admin/activation",
        component: () => import("./activation")
    },
    {
        path: "admin/suspensions",
        component: () => import("./suspensions")
    },

]
