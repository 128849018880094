import {DateTime} from "luxon";

export default {

    fileSizeFilter(size) {
        var i = Math.floor(Math.log(size) / Math.log(1024));
        return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i];
    },

    dateFormatFilter(isoDate, format) {
        if (!isoDate) return "";

        return DateTime
            .fromISO(isoDate)
            .setLocale("fr")
            .toFormat(format);
    }
}