import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import apiclient from "./services/networking/apiclient";
import user from "./services/accounting/user";
import "./styles/styles.scss"
import filters from "./services/misc/filters";

Vue.config.productionTip = false

Vue.prototype.JOS = {
    user,
    api: apiclient
}

// Filters
Vue.filter("fileSize", filters.fileSizeFilter);
Vue.filter("dateFormat", filters.dateFormatFilter);

new Vue({
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app')
